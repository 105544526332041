window.active_modules = [ "communicate" ];
const resourceName = document.querySelector("div[data-type]").getAttribute("data-type");

if (![ "timelinejs", "machforms", "scribble", "ttscribble", "instagram", "twittertweet", "twittertimeline", "pinterest", "podspace", "facebookpost", "flowplayer", "qbrick" ].includes(resourceName)) {
  window.active_modules.push("resize");
}
if (resourceName === "solidtango") {
  window.active_modules.push("video_messages");
}
if (resourceName === "flowplayer") {
  window.active_modules.push("flowplayer");
}
if (resourceName === "qbrick") {
  window.active_modules.push("kraken");
}
