(function () {
  if (window.active_modules.indexOf("communicate") === -1) {
    return;
  }

  let lastKnownHeight = 0;
  let counter = 0;

  const retryTimeout = 500;
  function checkHeightChanged() {
    const timeoutInSeconds = (retryTimeout / 1000);
    const triedForThreeSeconds = (timeoutInSeconds * counter) > 3;

    const heightHasChanged = lastKnownHeight !== document.body.offsetHeight;
    lastKnownHeight = document.body.offsetHeight;

    const sendPayload = heightHasChanged || !triedForThreeSeconds;
    if (sendPayload) {
      const payload = JSON.stringify({
        origin: location.href.replace(location.hash, ""),
        height: lastKnownHeight,
        count: counter,
      });

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(payload);
      } else {
        window.parent.postMessage(payload, "*");
      }
    }

    counter++;

    setTimeout(() => {
      checkHeightChanged();
    }, retryTimeout);
  }

  // Start listening for change
  checkHeightChanged();
})();
