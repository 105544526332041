/* eslint-disable */
(function() {
  if (window.active_modules.indexOf('video_messages') === -1) { return; }

  function mergeObject(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (source.hasOwnProperty(key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  }

  var VideoEventProxy = function () {
    var originUp = '*';
    var originDown = 'https://mittmedia.solidtango.com';
    var statusUpdateInterval = 1000;



    function VideoEventProxy(iframe) {
      this.iframe = iframe;
      this.initialized = false;
      this.metaData = {};

      window.addEventListener('message', this.receiveMessageDown.bind(this), false);

      this.startStatusTimer();
    }

    VideoEventProxy.prototype.startStatusTimer = function startStatusTimer() {
      var _this = this;

      window.setInterval(function (e) {
        _this.requestStatusUpdate();
      }, statusUpdateInterval);
    };

    VideoEventProxy.prototype.requestStatusUpdate = function requestStatusUpdate() {
      this.sendMessageDown(this.iframe, 'playing');
    };

    VideoEventProxy.prototype.requestMetaData = function requestMetaData() {
      this.sendMessageDown(this.iframe, 'metadata');
    };

    VideoEventProxy.prototype.sendMessageDown = function sendMessageDown(iframe, func) {
      var payload = {
        event: 'message',
        func: func
      };
      var message = JSON.stringify(payload);

      iframe.contentWindow.postMessage(message, originDown);
    };

    VideoEventProxy.prototype.sendMessageUp = function sendMessageUp(payload) {
      var message = JSON.stringify(payload);

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
        return;
      }

      window.parent.postMessage(message, originUp);
    };

    VideoEventProxy.prototype.receiveMessageDown = function receiveMessageDown(event) {
      var isNotSameOrigin = event.origin !== originDown;
      var isNotJSON = !(event && event.data && event.data.includes('{"'));
      if (isNotSameOrigin || isNotJSON) { return; }

      if (this.initialized === false) {
        this.initialized = true;
        this.requestMetaData();
        return;
      }
      var data = JSON.parse(event.data);

      switch (data.func) {
        case 'playing':
          var playing = data.args;
          var message = {
            type: 'solidTangoTrackPlaying',
            body: mergeObject({}, this.metaData, { 'playing': playing, 'src': this.iframe.src, 'origin': window.location.href })
          };
          this.sendMessageUp(message);
          break;
        case 'metadata':
          this.metaData = mergeObject({}, data.args);
          break;
        default:
          break;
      }
    };

    return VideoEventProxy;
  }();

  new VideoEventProxy(document.querySelector('iframe'));

})();
