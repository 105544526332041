/* eslint-disable */
let currentMediaId;

let videoStarted = false;
let adStarted = false;
let contentStarted = false;
let paused = false;
let ended = false;

let twentyFiveTriggered = false;
let fiftyTriggered = false;
let seventyFiveTriggered = false;
let ninetyTriggered = false;
let oneHundredTriggered = false;

let globalTimePassed = 0;
let globalAdTimePassed = 0;

let adTimePastCounterId;
let adTimePastCounterPaused = false;

let pulseIntervalId;
let pulsePaused = false;
let pulseCounter = 0;

function sendMessage(message) {
  return window.parent.postMessage({
    ...message,
    currentTime: message.playerState["is-live"] ? undefined : message.currentTime,
    duration: message.playerState["is-live"] ? undefined : message.duration,
    resourceId: window.location.pathname.split("/").pop(),
  }, '*');
}

function adTimePastCounter() {
  return setInterval(() => {
    if (!flowplayer || !flowplayer.instances[0] || adTimePastCounterPaused) {
      return;
    }

    globalAdTimePassed += 1;
  }, 1000);
}

function setupPulse() {
  ended = false;
  return setInterval(() => {
    if (!flowplayer || !flowplayer.instances[0] || pulsePaused) {
      return;
    }

    pulseCounter += 1;
    globalTimePassed += 1;

    if (pulseCounter === 15) {
      sendMessage({
        currentTime: Math.round(flowplayer.instances[0].currentTime),
        duration: Math.round(flowplayer.instances[0].duration),
        metadata: flowplayer.instances[0].opts.metadata,
        playerState: flowplayer.instances[0].playerState,
        type: "video-content-pulse",
        pastTime: globalTimePassed,
      });
      pulseCounter = 0;
      globalTimePassed = 0;
    }

    const instance = flowplayer.instances[0];
    const isLive = instance.playerState["is-live"];
    const duration = Math.round(instance.duration);
    const currentTime = Math.round(instance.currentTime);

    const twentyFive = duration * 0.25;
    const fifty = duration * 0.5;
    const seventyFive = duration * 0.75;
    const ninety = duration * 0.9;

    if (!isLive) {
      if (!twentyFiveTriggered && currentTime >= twentyFive) {
        twentyFiveTriggered = true;
        sendMessage({
          currentTime: currentTime,
          duration: duration,
          metadata: instance.opts.metadata,
          playerState: instance.playerState,
          type: "video-content-milestone",
          percent: "25",
          pastTime: globalTimePassed,
        });
        globalTimePassed = 0;
      }
      if (!fiftyTriggered && currentTime >= fifty) {
        fiftyTriggered = true;
        sendMessage({
          currentTime: currentTime,
          duration: duration,
          metadata: instance.opts.metadata,
          playerState: instance.playerState,
          type: "video-content-milestone",
          percent: "50",
          pastTime: globalTimePassed,
        });
        globalTimePassed = 0;
      }
      if (!seventyFiveTriggered && currentTime >= seventyFive) {
        seventyFiveTriggered = true;
        sendMessage({
          currentTime: currentTime,
          duration: duration,
          metadata: instance.opts.metadata,
          playerState: instance.playerState,
          type: "video-content-milestone",
          percent: "75",
          pastTime: globalTimePassed,
        });
        globalTimePassed = 0;
      }
      if (!ninetyTriggered && currentTime >= ninety) {
        ninetyTriggered = true;
        sendMessage({
          currentTime: currentTime,
          duration: duration,
          metadata: instance.opts.metadata,
          playerState: instance.playerState,
          type: "video-content-milestone",
          percent: "90",
          pastTime: globalTimePassed,
        });
        globalTimePassed = 0;
      }
    }
  }, 1000);
}

function relayToEmbedder(type, event) {
  // New video loaded, reset all tracking code
  if (currentMediaId && currentMediaId !== event.srcElement.opts.metadata.media_id) {
    clearInterval(pulseIntervalId);
    videoStarted = false;
    adStarted = false;
    contentStarted = false;
    paused = false;
    ended = false;

    twentyFiveTriggered = false;
    fiftyTriggered = false;
    seventyFiveTriggered = false;
    ninetyTriggered = false;
    oneHundredTriggered = false;

    globalTimePassed = 0;
    pulsePaused = false;
    pulseCounter = 0;
  }

  currentMediaId = event.srcElement.opts.metadata.media_id;

  if (type === "seeking") {
    pulsePaused = true;
    return;
  }

  if (type === "seeked") {
    pulsePaused = false;
    return;
  }

  const message = {
    currentTime: Math.round(event.srcElement.currentTime),
    duration: Math.round(event.srcElement.duration),
    metadata: event.srcElement.opts.metadata,
    playerState: event.srcElement.playerState,
    type,
  };

  if ((type === "playing" || type === "ad-started") && !videoStarted) {
    adStarted = true;
    videoStarted = true;

    sendMessage({
      ...message,
      type: "video-all-start",
      pastTime: globalTimePassed,
    });
    globalTimePassed = 0;

    if (type !== "ad-started") {
      contentStarted = true;
      pulseIntervalId = setupPulse();
      sendMessage({
        ...message,
        type: "video-content-start",
        pastTime: globalTimePassed,
      });
      globalTimePassed = 0;
      return;
    }
  }

  if (type === "ad-started") {
    adTimePastCounterId = adTimePastCounter();
  }

  if (type === "ad-paused") {
    adTimePastCounterPaused = true;
  }

  if (type === "ad-resumed") {
    adTimePastCounterPaused = false;
  }

  if (type === "ad-completed") {
    clearInterval(adTimePastCounterId);
  }

  if (type === "playing") {
    if (!contentStarted) {
      contentStarted = true;
      pulseIntervalId = setupPulse();
      sendMessage({
        ...message,
        type: "video-content-start",
        pastTime: globalTimePassed,
      });
      globalTimePassed = 0;
    }

    if (!paused) {
      return;
    }

    paused = false;
    ended = false;
    pulsePaused = false;

    sendMessage({
      ...message,
      type: "unpause",
      pastTime: globalTimePassed,
    });
    globalTimePassed = 0;
    return;
  }

  if (type === "pause" || type === "ended") {
    pulsePaused = true;
  }

  if (type === "pause") {
    if (event.srcElement.currentTime === event.srcElement.duration) {
      return;
    }

    paused = true;
  }

  if (type === "ended") {
    if (ended) {
      return;
    }

    paused = true;
    ended = true;

    if (!oneHundredTriggered) {
      oneHundredTriggered = true;
      sendMessage({
        currentTime: Math.round(flowplayer.instances[0].currentTime),
        duration: Math.round(flowplayer.instances[0].duration),
        metadata: flowplayer.instances[0].opts.metadata,
        playerState: flowplayer.instances[0].playerState,
        type: "video-content-milestone",
        percent: "100",
        pastTime: globalTimePassed,
      });
      globalTimePassed = 0;
    }
  }

  if (type.startsWith("ad-")) {
    sendMessage({
      ...message,
      pastTime: globalAdTimePassed,
    });
    globalAdTimePassed = 0;
  } else {
    sendMessage({
      ...message,
      pastTime: globalTimePassed,
    });
    globalTimePassed = 0;
  }
  return;
}

function callback(mutationList, observer) {
  for (const mutation of mutationList) {
    const video = document.querySelector("video");
    if (video) {
      video.addEventListener("ad-started", (e) => relayToEmbedder("ad-started", e), false);
      video.addEventListener("ad-paused", (e) => relayToEmbedder("ad-paused", e), false);
      video.addEventListener("ad-resumed", (e) => relayToEmbedder("ad-resumed", e), false);
      video.addEventListener("ad-completed", (e) => relayToEmbedder("ad-completed", e), false);
      video.addEventListener("playing", (e) => relayToEmbedder("playing", e), false);
      video.addEventListener("pause", (e) => relayToEmbedder("pause", e), false);
      video.addEventListener("ended", (e) => relayToEmbedder("ended", e), false);
      video.addEventListener("seeking", (e) => relayToEmbedder("seeking", e), false);
      video.addEventListener("seeked", (e) => relayToEmbedder("seeked", e), false);
      observer.disconnect();
      break;
    }
  }
}

(function () {
  if (window.active_modules.indexOf('flowplayer') === -1) { return; }

  var resourceContainerWrapper = document.querySelector('.resource-container-wrapper');

  function handleFlowplayerEvent(message) {
    if (message.data && message.data.consent) {
      var consent = message.data.consent;

      var allowedConsentCombinations = [9, 10, 17, 18];
      if (!allowedConsentCombinations.includes(consent)) {
        return console.error('Received bad consent combination', consent);
      }

      var body = document.querySelector('body');
      var script = document.createElement('script');
      script.innerHTML = 'flowplayer.cloud.then(function() {const player = flowplayer.instances[0];player.setOpts({consent: ' + consent + '});});';
      body.append(script);
    }
  }

  if (resourceContainerWrapper.classList.contains("resource-flowplayer")) {
    window.addEventListener("message", handleFlowplayerEvent, false);
    const observer = new MutationObserver(callback);
    const targetNode = document.querySelector("[data-player-id]");
    observer.observe(targetNode, { childList: true });
  }

})();
