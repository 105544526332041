(function () {
  const resourceFrame = document.querySelector("iframe");
  if (!resourceFrame) return;
  if (resourceFrame.src.indexOf("solidtango.com") === -1) return;

  function sendSolidtangoStop() {
    const message = {
      event: "message",
      func: "stop",
    };
    const jsonMessage = JSON.stringify(message);

    resourceFrame.contentWindow.postMessage(jsonMessage, resourceFrame.src);
  }

  window.sendSolidtangoStop = sendSolidtangoStop;
})();
